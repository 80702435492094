"use client";
import { cx } from "@tracksuit/utils";
import { useHubspotForm } from "next-hubspot";
import { useRef, type ComponentProps } from "react";
import { useFormValidation } from "./hooks/useFormValidation";
import styles from "./hubspot-form.module.css";

export type HubspotFormProps = {
  id?: string;
  title?: string;
  formId: string;
  portalId: string;
  submitButtonClass?: string;
  GAEventName?: string;
  noLabels?: boolean;
} & ComponentProps<"div">;

/**
 * @component
 *
 */
export function HubspotForm({
  id,
  title,
  formId,
  portalId,
  submitButtonClass,
  className,
  GAEventName,
  noLabels
}: HubspotFormProps) {
  // Dynamic id
  const hsFormId = useRef<string>(`form-${id ?? 0}`);
  // Init Hubspot form
  const hubpsotForm = useHubspotForm({
    portalId,
    formId,
    target: `#${hsFormId.current}`,
    formInstanceId: id,
    submitButtonClass: submitButtonClass
  });

  // Init validation and custom styling
  useFormValidation(hubpsotForm, formId, hsFormId.current, GAEventName);

  return (
    <>
      {title ? <h3 className={styles.title}>{title}</h3> : null}
      <div
        id={hsFormId.current}
        className={cx(
          className,
          "hubspot-form",
          "hubspot-form-init",
          noLabels && "--no-labels"
        )}
      ></div>
    </>
  );
}
