import { cx } from "@tracksuit/utils";
import { resolveLink } from "$src/lib/sanity";
import Link from "next/link";
import styles from "./testimonial-quote-tile.module.css";
import tileStyles from "../testimonial-tile.module.css";
import type { ComponentProps } from "react";
import type { TestimonialData } from "../testimonial-types";

export type TestimonialProps = TestimonialData &
  ComponentProps<"div"> & { region: string; rounded?: boolean };

/**
 * @component
 *
 */
export function TestimonialQuoteTile({
  className,
  color,
  company,
  labeledLink,
  name,
  quote,
  region,
  role,
  rounded = false,
  ...props
}: TestimonialProps) {
  return (
    <div
      className={cx(
        styles["testimonial-quote-tile"],
        tileStyles["testimonial-tile"],
        color && styles["testimonial-width"],
        !color && styles.small,
        rounded && styles["--rounded"],
        styles[color ?? ""],
        className
      )}
      {...props}
    >
      <div className={styles.container}>
        <div className={styles.quote}>
          <div className={styles["curly-apostrophe"]}>“</div>
          {quote}
        </div>
        <p className={styles["details-container"]}>
          <span className={styles.details}>
            <span>
              {name}
              {role || company ? <>,</> : null}
            </span>
            {role ? <span>&nbsp;{role}</span> : null}
            {company ? <span>&nbsp;at {company}</span> : null}
          </span>
        </p>
        {region && labeledLink ? (
          <div className={styles["button-container"]}>
            <Link
              href={resolveLink(region, labeledLink.link)}
              className={cx(styles.button, styles[color || ""])}
            >
              {labeledLink.label}
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );
}
