"use client";

import mixins from "$src/styles/mixins.module.css";
import { cx } from "@tracksuit/utils";
import type { Colors } from "@tracksuit/utils";
import type { SanityImageAssetDocument } from "next-sanity";
import { type ComponentProps } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Mousewheel } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { TestimonialImageTile } from "../testimonial/testimonial-image-tile/testimonial-image-tile";
import { TestimonialQuoteTile } from "../testimonial/testimonial-quote-tile/testimonial-quote-tile";
import type { TestimonialData } from "../testimonial/testimonial-types";
import styles from "./testimonial-carousel.module.css";
import swiperCommon from "$src/lib/swiper-common";

type Testimonial = TestimonialData & {
  image?: SanityImageAssetDocument;
  color?: Colors;
};

export type SlideData = {
  testimonial: Testimonial;
  _id: string;
};

type CarouselProps = {
  slides: SlideData[];
  region: string;
  large?: boolean;
  spacing?: boolean;
} & ComponentProps<"div">;

/**
 * @component
 * Carousel containing a testimonial and image
 */
export function TestimonialCarousel({
  large = false,
  spacing = true,
  children,
  className,
  region,
  slides,
  ...props
}: CarouselProps) {
  return (
    <>
      {slides && (
        <div
          className={cx(
            styles["testimonials-wrapper"],
            !large ? styles["testimonials-background"] : null
          )}
        >
          <Swiper
            pagination={{
              el: ".testimonials-pagination",
              dynamicBullets: true,
              clickable: true
            }}
            {...swiperCommon}
            modules={[Mousewheel, Pagination, Navigation]}
          >
            {slides.map(({ testimonial, _id }: SlideData, index: number) => (
              <SwiperSlide key={_id} className={styles["testimonial-slide"]}>
                <div className={spacing ? mixins["grid-page"] : ""}>
                  <div className={mixins["grid-content"]}>
                    <div
                      className={
                        !large && testimonial?.image
                          ? styles["testimonial-tile-twoup"]
                          : styles["testimonial-tile"]
                      }
                    >
                      <TestimonialQuoteTile
                        region={region}
                        quote={testimonial?.quote}
                        color={large ? testimonial?.color : undefined}
                        name={testimonial?.name}
                        role={testimonial?.role}
                        company={testimonial?.company}
                        labeledLink={testimonial?.labeledLink}
                      />
                    </div>

                    {!large && testimonial?.image && (
                      <div className={styles["testimonial-tile-twoup"]}>
                        <TestimonialImageTile image={testimonial.image} />
                      </div>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className={styles["testimonials-pagination-container"]}>
            <div className="testimonials-pagination"></div>
          </div>
        </div>
      )}
    </>
  );
}
